import { Email, Phone } from "../icons/icon";
import React from "react";
import { useTranslation } from "react-i18next";

function Footer(props) {
  const { t, i18n } = useTranslation();

  return (
    <div className="w-full bg-[#4A4A4A] flex justify-center mt-20 font-serif">
      <div className="container flex flex-col justify-center items-center text-center">
        <h1 className="pt-20 md:text-3xl text-xl text-white font-serif">
          UzPetroleum.com
        </h1>
        <p className="lg:w-3/6 pt-10 lg:text-xl text-sm  lg:px-0 px-5  text-white">
          {t("FooterText")}
        </p>
        <div className="lg:w-2/6 w-5/6 mt-12 flex justify-center">
          <div className="pt-10">
          <Email /> 
          </div>
          <h1 className="text-white font-sans pl-5 md:text-2xl text-xl mt-8">uzbpetroleum@gmail.com</h1>
        </div>

        <div className="flex">
          <div className="pt-10">
          <Phone />
          </div>
          <h1 className="text-white font-sans pl-5 md:text-2xl text-xl mt-8">
            +998-99-840-84-84
          </h1>
        </div>
        <p className="md:text-sm text-[10px] text-gray-400 mt-16 mb-10 md:px-0 px-5 font-sans">
          Copyright ©<span>{(new Date().getFullYear())}</span> All Rights reserved | This template is made with ❤️ by
          Console.log Company
        </p>
      </div>
    </div>
  );
}


export default Footer;
