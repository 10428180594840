import React from "react";
import { Collapse } from "react-collapse"
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai"

const Accordion = ({ open, toggle, title, desc }) => {

    return (
      <div id="accordion" className="w-full font-serif">
          <div className="bg-gray-100 border-b border-gray-500 md:mx-0 mx-3 py-10 px-3 flex justify-between items-center cursor-pointer mt-1" onClick={toggle}>
              <p className="text-[22px] font-semibold">{title}</p>
              <div className="text-[28px]">
                  {open ? <AiOutlineMinus/> : <AiOutlinePlus/>}
              </div>
          </div>
  
          <Collapse isOpened={open}> 
          <div className="bg-gray-100 text-xl px-5 pb-5 pt-5">
              {desc}
          </div>
          </Collapse>
      </div>
    );
  }
  export default Accordion;