import carousel3 from "../img/carousel3.jpg";
import carousel7 from "../img/carusel.jpg";
import carousel6 from "../img/carousel6.jpg";

import carousel9 from "../img/Butum.jpg";
import carousel10 from "../img/Aviabenzin.jpg";
import { Left, Right } from "../icons/icon";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

function OverviewCarousel({ next, previous }) {
  const { t } = useTranslation();

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div
        id="carousel"
        className="carousel-button-group mb-4 gap-2 flex justify-end
            items-center w-full absolute top-0 left-0 -mt-2"
      >
        <button className="block md:p-3 -mt-4" onClick={() => previous()}>
          <div className="w-10 pl-2">
            <Left />
          </div>
        </button>
        <button onClick={() => next()}>
          <Right />
        </button>
      </div>
    );
  };

  return (
    <div className="lg:mt-32 mt-10">
      <div className="container m-auto relative py-20">
        <h1 className="font-bold font-serif text-sm md:text-2xl -mt-16 ml-10 lg:ml-2 text-black">
          {t("CarouselHead")}
        </h1>
        <Carousel
          className="w-full lg:px-10 flex"
          autoPlay={true}
          autoPlaySpeed={2000}
          transitionDuration={500}
          infinite={true}
          swipeable={true}
          responsive={responsive}
          arrows={false}
          renderButtonGroupOutside={true}
          shouldResetAutoplay={true}
          customButtonGroup={<ButtonGroup />}
        >
          <div className="mt-10">
            <div className="flex flex-col p-5 gap-5 isolate font-serif bg-white w-10/12 mx-8 lg:mx-0 shadow-md shadow-black mb-5">
              <div className="lg:mx-5 my-7">
                <img src={carousel7} width={347} height={230} alt="#" />
                <h1 className="font-bold md:text-2xl text-black mt-5 w-11/12">
                  {t("CarouselOne")}
                </h1>
                <p className="text-black md:text-lg text-sm pt-2">
                  {" "}
                  {t("CarouselText")}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-10">
            <div className="flex flex-col p-5 gap-5 isolate font-serif bg-white w-10/12 mx-8 lg:mx-0 shadow-md shadow-black mb-5">
              <div className="lg:mx-5 my-5">
                <img src={carousel3} width={347} height={230} alt="#" />
                <h1 className="font-bold md:text-2xl text-black mt-5 w-11/12">
                  {t("CarouselTwo")}
                </h1>
                <p className="text-black pt-2 md:text-lg text-sm">
                  {t("CarouselText")}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-10">
            <div className="flex flex-col p-[18px] gap-5 isolate font-serif bg-white w-10/12 mx-8 lg:mx-0 shadow-md shadow-black mb-5">
              <div className="lg:mx-5 my-5">
                <img
                  width={347}
                  height={230}
                  src={carousel6}
                  className=" object-cover"
                  alt="#"
                />
                <h1 className="font-bold md:text-2xl text-black mt-4 xl:mt-2">
                  {t("CarouselThere")}
                </h1>
                <p className="text-black md:text-base text-sm w-11/12">
                  {t("CarouselText")}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <div className="flex flex-col px-5 py-5 xl:py-2 gap-5 isolate bg-white font-serif w-10/12 mx-8 lg:mx-0 shadow-md shadow-black mb-5">
              <div className="lg:mx-5 ">
                <img src={carousel9} className=" object-cover" alt="#" />
                <h1 className="font-bold md:text-2xl text-black  w-11/12">
                  {t("CarouselSix")}
                </h1>
                <p className="text-black md:text-lg xl:pt-2 text-sm">
                  {t("CarouselText")}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-10">
            <div className="flex flex-col py-5 px-3 gap-5 isolate font-serif bg-white w-10/12 mx-8 lg:mx-0 shadow-md shadow-black mb-5">
              <div className="lg:mx-5 my-5">
                <img src={carousel10} width={367} height={500} alt="#" />
                <h1 className="font-bold md:text-2xl text-black mt-3">
                  {t("CarouselFor")}
                </h1>
                <p className="text-black pt-2 md:text-lg text-sm w-11/12">
                  {t("CarouselText")}
                </p>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default OverviewCarousel;
