import { useState } from "react";
import Accordion from "./Accordion";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const toggle = (index) => {
    if (open === index) {
      return setOpen(null);
    }

    setOpen(index);
  };

  const accordionData = [
    {
      title: `${t("faqSavol1")}`,
      desc: `${t("faqJavob1")}`,
    },
    {
      title: `${t("faqSavol2")}`,
      desc: `${t("faqJavob2")}`,
    },
    {
      title: `${t("faqSavol3")}`,
      desc: `${t("faqJavob3")}`,
    },
    {
      title: `${t("faqSavol4")}`,
      desc: `${t("faqJavob4")}`, 
    },
  ];

  return (
    <div>
      <section className="w-full bg-gray-100 grid place-items-center mb-5">
        <div className="container bg-gray-100">
          {accordionData.map((data, index) => {
            return (
              <Accordion
                key={index}
                open={index === open}
                title={data.title}
                desc={data.desc}
                toggle={() => toggle(index)}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
}
