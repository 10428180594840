import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function OverviewForm(props) {
  const { t, i18n } = useTranslation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [text, setText] = useState("");
  const token = "6528274454:AAFejWIWk22qHivdbqUG0-iwehkstalvrdI";
  const chatId = "-1001708399268";
  async function sendMassageFunction() {
    let url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=%0A-Ism: ${name}, %0A- Email: ${email} %0A- Telefon: ${phone},  %0A- Xabar: ${text}, %0A`;
    const res = await fetch(url);
    console.log(res);
    setName("");
    setEmail("");
    setPhone("");
    setText("");
  }

  return (
    <div
      id="form"
      className="w-full flex justify-center overflow-x-hidden mt-14 bg-gray-100">
      <div className="container h-auto lg:flex font-serif">

        <div className="lg:w-1/2">
          <h1 className="lg:text-2xl md:text-xl text-black text-md font-bold 2xl:px-8 xl:px-7 lg:px-10 md:px-8 sm:px-14 px-5">
            {t("FormHead")}
          </h1>
          <form className="w-full flex flex-col items-center">
            <input
              className="md:w-11/12 w-10/12 px-5 md:py-4 py-2 mt-5 rounded-md outline-none"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="name"
              placeholder={t("FormImg")}
            />
            <input
              className="md:w-11/12 w-10/12 px-5 md:py-4 py-2 mt-5 rounded-md outline-none"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              placeholder={t("FormEmail")}
            />
            <input
              className="md:w-11/12 w-10/12 px-5 md:py-4 py-2 mt-5 rounded-md outline-none"
              type="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              id={"phone"}
              placeholder={t("FormTel")}
            />
            <textarea
              className="md:w-11/12 w-10/12 md:py-2 py-1 px-5 mt-5 rounded-md outline-none"
              placeholder={t("FormXabar")}
              value={text}
              onChange={(e) => setText(e.target.value)}
              id={"text"}
              name="Message"
              rows={4}
            />
          </form>
          <button
            onClick={sendMassageFunction}
            type="submit"
            className="xl:w-56 w-32 xl:py-5  2xl:mx-8 xl:mx-7 md:mx-8 sm:mx-14 mx-5  py-4 mt-5 rounded-md bg-[#8EC641] text-white xl:text-xl text-xs font-bold mb-7"
          >
            {t("FormButton")}
          </button>
        </div>

        <div className="lg:w-1/2 flex items-center lg:justify-end md:justify-center justify-center md:mx-0 mx-5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2996.209803251737!2d69.30216071126583!3d41.326050971187996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDE5JzMzLjgiTiA2OcKwMTgnMTcuMSJF!5e0!3m2!1sru!2s!4v1691227301444!5m2!1sru!2s"
            width="600"
            height="450"
            title="myFrame"
            className="border-0"
            loading="lazy"
          ></iframe>
        </div>

      </div>
    </div>
  );
}

export default OverviewForm;
