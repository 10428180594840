import React from "react";
import infoone from "../img/InfoOne.jpg";
import infotwo from "../img/InfoTwo.jpg";
import infothere from "../img/InfoTheere.jpg";
import moy6 from "../img/moy6.jpg"
import infosix from "../img/OverviewTovar/TavarSix.jpg"
import infoNayt from "../img/OverviewTovar/TavarNayt.jpg"
import { useTranslation } from "react-i18next";

function OverviewInfo() {
  const { t, i18n } = useTranslation();

    return (
      <div id="info" className="">
        <div className="container px-4 2xl:px-0 mx-auto xl:flex justify-between xl:py-20">
          <div className="xl:w-6/12 font-serif">
            <h1 className="text-xl xl:text-2xl font-bold font-Poppins">  {t("InfoHead")}</h1> <br/>
            <p className="text-[#4E4F4E;] flex font-Poppins text-base xl:text-2xl  mt-2">
              {t("InfoTextOne")} <br/> <br className="hidden xl:block" />
              {t("InfoTextTwo")} <br/> <br className="hidden xl:block" />
              {t("InfoTextThere")} <br/> <br className="hidden xl:block" />
              {t("InfoTextFor")} <br/> <br className="hidden xl:block" />
              {t("InfoTextSix")} <br/>  <br className="hidden xl:block" />
            </p>
          </div>
          <div className="mt-10 md:mt-0 xl:w-6/12 xl:ml-10">
            <div className="img_left flex">
              <div className="xl:w-11/12">
                <img
                    alt="#"
                  src={infoone}
                  className="object-cover mt-10 xl:mt-20 hidden lg:block"
  
                />
              </div>
              <div className="xl:ml-2 xl:w-10/12">
                <img  alt="nice" src={infotwo} className="object-cover" />
                <img
                    alt="nice"
                            src={infothere}
                  className="object-cover mt-2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container px-4 2xl:px-0 mx-auto flex flex-col-reverse xl:flex-row justify-between">
          <div className="xl:w-6/12 mt-10 xl:mt-0">
            <div className="img_left flex">
              <div className="mr-2 xl:w-11/12">
                <img  alt="nice" src={infoNayt} className="object-cover" />
                <img
                    alt="nice"
                    src={infosix}
                    className="object-cover mt-2"
                />
              </div>
              <div>
                <img
                    alt="nice"
                    src={moy6}
                    className="object-cover mt-10 xl:mt-10 hidden lg:block"

                />
              </div>
            </div>
          </div>
          <div className="xl:w-6/12 xl:ml-14 font-serif">
            <h1 className="text-xl xl:text-2xl font-bold mt-10 xl:mt-0 font-Poppins">{t("info")}</h1> <br/>
            <p className="text-[#4E4F4E;] flex font-Poppins text-base xl:text-2xl  xl:mt-2">
                {t("text")} <br/>
                {t("text2")} <br/>
                {t("text3")} <br/>
                {t("text4")} <br/>
                {t("text5")} <br/>
                {t("text6")} <br/>
                {t("text7")} <br/>
                {t("text8")} <br/>
                {t("text9")} <br/>
                {t("text10")} <br/>
            </p>
          </div>
        </div>
      </div>
    );
  }
  export default OverviewInfo;