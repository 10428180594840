import React from "react";
import Navbar from "../src/components/Navbar";
import OverViewHeader from "./containers/OverViewHeader";
import OverViewCarousel from "./containers/OverViewCarousel";
import OverViewInfo from "./containers/OverViewInfo";
import OverviewForm from "./containers/OverViewForm";
import OverViewAccordion from "./containers/OverViewAccordion";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="bg-gray-100 main">
      <div className="bgr">
        <Navbar />
        <OverViewHeader />
      </div>
      <OverViewCarousel next={undefined} previous={undefined} />
      <OverViewInfo />
      <OverviewForm />
      <OverViewAccordion />
      <Footer />
    </div>
  );
}

export default App;
