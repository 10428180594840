import React, { useState } from "react";
import logo from "../img/uz petrol-01.png";
import { HamburgerIcon, XIcon } from "../icons/icon.js";
import { MobileMenu } from "../components/MobileMenu";
import { Link } from "react-scroll/modules";
import { useTranslation } from "react-i18next";

function Navbar() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lenguage) => {
    i18n.changeLanguage(lenguage);
  };

  const [state, setState] = useState(false);

  const [mobile, setMobile] = useState(false);

  return (
    <div className="w-full flex justify-center">
      <div className="container h-32 flex">
        <div className="md:w-1/6 w-3/5 h-full flex items-center md:justify-center md:px-0 px-5">
          <Link to="/">
            <div className="Image">
              <img
                alt="nice"
                src={logo}
                width={180}
                className="rounded-md cursor-pointer object-cover "
              />
            </div>
          </Link>
        </div>

        <div className="w-7/12 h-full flex items-center">
          <div className="w-full flex justify-evenly xl:text-xl lg:text-xl md:text-sm  text-white cursor-pointer">
            <Link to="header" duration={5000}>
              <p className="font-medium md:block hidden font-serif">
                {t("nav1")}
              </p>
            </Link>
            <Link to="carousel" duration={5000}>
              <p className="font-medium md:block hidden font-serif">
                {t("nav2")}
              </p>
            </Link>
            <Link to="info" duration={5000}>
              <p className="font-medium md:block hidden font-serif">
                {t("nav3")}
              </p>
            </Link>
            <Link to="product" duration={5000}>
              <p className="font-medium md:block hidden font-serif">
                {t("nav4")}
              </p>
            </Link>
            <Link to="accordion" duration={5000}>
              <p className="font-medium md:block hidden font-serif">
                {t("nav5")}
              </p>
            </Link>
          </div>
        </div>

        <div className="md:w-1/6 w-full h-full flex items-center justify-end">
          <div class="relative group">
            <div class="md:w-20 md:h-10 w-16 h-8 flex items-center justify-center cursor-pointer text-sm text-blue border text-black font-bold bg-gray-100 border-b-0  group-hover:border-grey-light rounded-t-lg py-1 px-2">
              <p>{t("trans")}</p>
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
            <div class="items-center absolute border border-t-0 rounded-b-lg p-1 bg-white  invisible group-hover:visible w-full">
              <a
                onClick={() => changeLanguage("uz")}
                href="#"
                class="px-4 py-1 block text-md text-black hover:bg-grey-lighter"
              >
                Uz
              </a>
              <a
                onClick={() => changeLanguage("rus")}
                href="#"
                class="px-4 py-1 block text-md text-black hover:bg-grey-lighter"
              >
                Rus
              </a>
              <a
                onClick={() => changeLanguage("en")}
                href="#"
                class="px-4 py-1 block text-md text-black hover:bg-grey-lighter"
              >
                En
              </a>
            </div>
          </div>

          <button className="lg:w-32 h-11 bg-[#8EC641] md:block hidden font-serif ml-1 rounded-sm text-white lg:text-xl">
            <a
              href="tel:+998998408484"
              className="lg:text-xl font-bold text-sm lg:p-0 px-6"
            >
              {t("nav6")}
            </a>
          </button>

          <button
            className="md:w-10 w-full flex items-center justify-center h-10 mr-5 z-40 inline md:hidden "
            onClick={() => setMobile(!mobile)}
          >
            {state && (
              <div className="z-50 fixed -mt-0">
                <XIcon />
              </div>
            )}
            {!mobile && <HamburgerIcon />}
          </button>
        </div>

        <div className="inline lg:hidden">
          {mobile && (
            <MobileMenu open={mobile} close={() => setMobile(false)} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
