import React, { Fragment } from "react";
import { XIcon } from "../icons/icon";
import logo from "../img/uz petrol-01.png"
import { Link } from "react-scroll/modules";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

export function MobileMenu({ open, close }) {
  const { t } = useTranslation();


  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        onClose={close}
        className="fixed inset-0 z-50 overflow-y-auto justify-between xl:ml-40 2xl:ml-80 overflow-x-hidden"
      >
        <div className="bg-gray-100 w-full fixed py-5 px-5 md:px-0 overflow-x-hidden">
          <div className="flex justify-between">
            <div className="lg:w-8/12 ml-1.5 xl:ml-0 flex items-center justify-start pt-7">
              <Link to="header" duration={5000}>
                <h1 className="md:text-xl text-xl font-serif font-extrabold text-black">
                  Uzpetroleum
                </h1>
              </Link>
            </div>
            <div className="lg:mt-7 pt-7 mr-3" onClick={close}>
              <XIcon />
            </div>
          </div>
          <ul className="w-full flex flex-col items-center font-text py-5">
            <li className="mt-8">
              <Link
                to="header"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                data-bs-dismiss="modal"
              >
                <p
                  onClick={close}
                  className="md:text-xl text-lg text-black sm:text-3xl mb-1 font-medium font-serif tracking-widest"
                >
                  {t("nav1")}
                </p>
              </Link>
            </li>

            <li className="mt-8">
              <Link to="carousel" duration={5000}>
                <p
                  onClick={close}
                  className="md:text-xl text-lg text-black sm:text-3xl mb-1 font-medium font-serif  tracking-widest"
                >
                  {t("nav2")}
                </p>
              </Link>
            </li>

            <li className="mt-8">
              <Link to="info" duration={5000}>
                <p
                  onClick={close}
                  className="md:text-xl text-lg text-black sm:text-3xl mb-1 font-medium font-serif tracking-widest"
                >
                  {t("nav3")}
                </p>
              </Link>
            </li>

            <li className="mt-8">
              <Link to="product" duration={5000}>
                <p
                  onClick={close}
                  className="md:text-xl text-lg text-black sm:text-3xl mb-1 font-medium font-serif tracking-widest"
                >
                  {t("nav4")}
                </p>
              </Link>
            </li>

            <li className="mt-8">
              <Link to="form" duration={5000}>
                <p
                  onClick={close}
                  className="md:text-xl text-lg text-black sm:text-3xl mb-1 font-medium font-serif tracking-widest"
                >
                  {t("nav5")}
                </p>
              </Link>
            </li>
            <li className="mt-8">
              <Link to="header" duration={5000}>
                <img src={logo} width={120} className="rounded-md" alt="#" />
              </Link>
            </li>
          </ul>

          <div className="w-full flex flex-col justify-center items-center mt-10">
            <div className="md:w-5/12 flex">
              {/* <a href='https://www.facebook.com/Islamkhan.makhmudjanov'>
                  <FacebookIcon />
                </a>
                <a
                  href='https://www.instagram.com/islommakhmudovv/?next=%2F'
                  className='ml-6'
                >
                  <InstagramIcon />
                </a>
                <a href='https://t.me/islommakhmudovv' className='ml-6'>
                  <TelegramIcon />
                </a>
                <a
                  href='https://www.twitter.com/IslomMakhmudovv'
                  className='ml-6'
                >
                  <TwitterIcon />
                </a> */}
            </div>
            <p className="md:text-xl text-[10px] mt-8 md:mb-4 mb-60 tracking-wide text-black">
              © 2023 Copy Right. All rights reserved.
            </p>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
