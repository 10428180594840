import React from "react";
import { useTranslation } from "react-i18next";

// import Location from "../img/location.png";
// import Money from "../img/dollar-circle.png";
// import House from "../img/house.png";
// import home from "../img/home1.jpg";

export default function OverviewHeader() {
  const { t } = useTranslation();

  return (
    <div id="header" className="w-full flex justify-center">
      <div className="container flex mt-12 md:mb-60 mb-40">
        <div className=" w-full z-20 md:mx-0 mx-4">
          <h1 className="2xl:text-6xl lg:text-4xl font-serif text-white text-center font-semibold  text-2xl">
            {t("header1")} <br />
          </h1>
          <p className="md:text-2xl font-serif text-base text-white text-center pt-4 xl:pt-10 xl:mx-28 xl:w-9/12">
            {t("header2")}
          </p>
          {/*<div className="my-div md:w-4/4 md:h-24 h-16 flex rounded-md mt-10">*/}
          {/*  <div className="w-1/3 flex items-center justify-center">*/}
          {/*    <div className="lg:w-12 lg:h-12 md:w-14 md:h-14 rounded-full md:bg-[#f0f6fa] flex justify-center items-center">*/}
          {/*      <img src={Location} width={20} alt="" />*/}
          {/*    </div>*/}
          {/*    <div className="ml-2">*/}
          {/*      <p className="lg:text-xl font-serif md:text-sm text-black sm:text-[13px] text-[7px] font-bold">*/}
          {/*        Manzil*/}
          {/*      </p>*/}
          {/*      <p className="lg:text-lg font-serif md:text-sm sm:text-[10px] text-[6px] text-white font-bold md:pt-2">*/}
          {/*        Toshkent Uzbekiston*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*  </div>*/}

          {/*  <div className="w-1/3 flex items-center justify-center">*/}
          {/*    <div className="lg:w-12 lg:h-12 md:w-14 md:h-14 rounded-full md:bg-[#f0f6fa] flex justify-center items-center">*/}
          {/*      <img src={Money} width={20} alt="" />*/}
          {/*    </div>*/}
          {/*    <div className="ml-2">*/}
          {/*      <p className="lg:text-xl font-serif md:text-sm text-black sm:text-[13px] text-[7px] font-bold">*/}
          {/*        Price*/}
          {/*      </p>*/}
          {/*      <p className="lg:text-lg font-serif md:text-sm sm:text-[10px] text-[6px] text-white font-bold md:pt-2">*/}
          {/*        $1000 - $10,000*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*  </div>*/}

          {/*  <div className="w-1/3 flex items-center justify-center">*/}
          {/*    <div className="lg:w-12 lg:h-12 md:w-14 md:h-14 rounded-full md:bg-[#f0f6fa] flex justify-center items-center">*/}
          {/*      <img src={House} width={20} alt="" />*/}
          {/*    </div>*/}
          {/*    <div className="ml-2">*/}
          {/*      <p className="lg:text-xl font-serif md:text-sm text-black sm:text-[13px] text-[7px] font-bold">*/}
          {/*        Maxsulot*/}
          {/*      </p>*/}
          {/*      <p className="lg:text-lg font-serif md:text-sm sm:text-[10px] text-[6px] text-white font-bold md:pt-1">*/}
          {/*        Salarka*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="md:w-4/5 mt-16 flex px-2d">*/}
          {/*  <div className="md:w-3/12 w-1/2">*/}
          {/*    <p className="md:text-3xl text-xl font-bold font-Poppins text-black">*/}
          {/*      2000+{" "}*/}
          {/*    </p>*/}
          {/*    <p className=" font-medium font-Poppins text-black">*/}
          {/*      Property Ready*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*  <div className="md:w-3/12 w-1/2">*/}
          {/*    <p className="md:text-3xl text-xl font-bold font-Poppins text-black">*/}
          {/*      500+{" "}*/}
          {/*    </p>*/}
          {/*    <p className="font-medium font-Poppins text-black">*/}
          {/*      Happy Customer*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>

        {/*<div className="w-3/12 flex relative  justify-center xl:right-10 right-36 lg:block hidden">*/}
        {/*  <div className="w-96 rounded-t-full">*/}
        {/*    <img*/}
        {/*      src={home}*/}
        {/*      alt=""*/}
        {/*      className="object-cover h-[550px] rounded-t-full"*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
